<template>
    <div class="layout-subcontainer" v-if="USER_ID == 'teletest'">

        <div class="box-wrap">
            <h1 class="tit-h1 d-flex">
                파일 관리
            </h1>
            <div class="box-ct mt-2">
                <div class="table-form d-flex">
                    <table>
                        <colgroup>
                            <col width="10%" />
                            <col width="90%" />
                        </colgroup>
                        <tbody>
                            <tr>
                                <th scope="row">
                                    <v-badge dot color="#EF9191">조회 조건</v-badge>
                                </th>
                                <td>
                                    <v-text-field 
                                        class="form-inp lg ml-2" 
                                        outlined 
                                        hide-details 
                                        label="제목 or 파일명" 
                                        @keyup.enter="clickGetList"
                                        v-model.trim="SEARCH_PARAMS"
                                    >
                                        <template v-slot:append>
                                            <v-btn
                                                depressed
                                                icon
                                                x-small
                                                @click="clickGetList"
                                                class="fomr-inp--btn svg-search"
                                            />
                                        </template>
                                    </v-text-field>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="box-wrap">
            <h2 class="tit-h2 d-flex">
                파일 리스트
                <div class="ml-auto align-self-center">
                    <v-btn class="btn-default" outlined @click="clickNewWrite">
                        신규
                    </v-btn>
                    <v-btn class="btn-etc ml-2" outlined @click="fnMultiDelWrite">
                        삭제
                    </v-btn>
                </div>
            </h2>

            <div class="box-ct">
                <v-data-table 
                    class="grid-default case03" 
                    dense fixed-header
                    :headers="gridListDataHeaders" 
                    :items="gridListDataText" 
                    :items-per-page="itemsPerPage"
                    item-key="DOWN_URL" 
                    :page.sync="page" 
                    hide-default-footer 
                    @page-count="pageCount = $event"
                    :item-class="isActiveRow" 
                    @click:row="rowClick" 
                    show-select
                    v-model="gridListItemSelected"
                >
                    <!-- <template v-slot:item.DEL="{item}">
                        <button
                            type="button"
                            @click="fnClickDelWrite(item)"
                        >
                            <v-icon
                                color="primary"
                                small
                                style="color:red; !important"
                                class="mr-2">mdi-delete
                            </v-icon>
                        </button>
                    </template> -->
                    <template v-slot:item.EDIT="{item}">
                        <button
                            type="button"
                            @click="fnModi(item)"
                        >
                            <v-icon
                                color="primary"
                                small
                                class="mr-2">mdi-pencil
                            </v-icon>
                        </button>
                    </template>
                    
                    <template v-slot:item.DOWN_URL_BTN={item}>
                        <div>
                            <a :href="(item.DOWN_URL_BTN+'/'+item.UPLD_NAME)" target="_blank" download>
                                <v-btn v-if="item.DOWN_URL_BTN!=null&&item.DOWN_URL_BTN!=''" :class="{color:item.DOWN_URL_BTN, small:item.DOWN_URL_BTN===false}">다운로드</v-btn>
                            </a>
                        </div>
                    </template>
                    <template v-slot:item.DOWN_URL={item}>
                        <div>
                            <v-btn v-if="item.DOWN_URL!=null&&item.DOWN_URL!=''" :class="{color:item.DOWN_URL, small:item.DOWN_URL===false}" @click="fnDownLinkCopy(item)">다운로드 주소 복사</v-btn>
                            <input type="hidden" :id="item.FILE_ID" :value="item.DOWN_URL+'/'+item.UPLD_NAME" />
                        </div>
                    </template>
                </v-data-table>
                <div class="grid-paging text-center pt-2">
                    <v-pagination 
                        v-model="page" 
                        :length="pageCount" 
                        :total-visible="totalVisible"
                        next-icon="svg-paging-next" 
                        prev-icon="svg-paging-prev">
                    </v-pagination>
                </div>
            </div>
        </div>

        <!-- 신규 모달창 -->
        <div class="ml-auto">
            <v-dialog 
                v-if="DialogCOP0600" 
                max-width="500" 
                content-class="square-modal min-auto"
                persistent 
                v-model="DialogCOP0600"
            >
                <dialog-COP0600 
                    :param="COP0600Param" 
                    @hide="hideDialog('COP0600')"
                    @submit="submitDialog('COP0600')"
                >
                </dialog-COP0600>

            </v-dialog>
        </div>
    </div>
</template>
  
<script>
import { mixin } from "@/mixin/mixin";
import DialogCOP0600 from "./COP0600";

export default {
    name: "MENU_COM0600", //name은 'MENU_' + 파일명 조합
    components: {
        DialogCOP0600,
    },
    mixins: [mixin],
    data() {
        return {
            baseUrl:process.env.VUE_APP_API_URL,
            DialogCOP0600: false,
            COP0600Param: {},
            selectedRow: null,
            selectedRows:[],
            USER_ID: this.$store.getters["userStore/GE_USER_ID"],
            FILE_MAX_SIZE:0,

            // 조회 v-model
            SEARCH_PARAMS: '',

            //리스트
            page: 1,
            pageCount: 0,
            itemsPerPage: 20,
            totalVisible: 10,

            gridListItemSelected: [],
            gridListDataSelect: [],
            gridListDataText: [],
            gridListDataHeaders: [
                {
                    text: "No.",
                    value: "ROW_NUM",
                    align: "center",
                    width: "3%",
                    sortable: true,
                },
                {
                    text: "다운로드",
                    value: "DOWN_URL_BTN",
                    align: "center",
                    width: "5%",
                    sortable: true,
                },
                {
                    text: "다운로드 주소 복사",
                    value: "DOWN_URL",
                    align: "center",
                    width: "8%",
                    sortable: true,
                },
                {
                    text: "제목",
                    value: "TITLE",
                    align: "center",
                    sortable: true,
                },
                {
                    text: "파일명",
                    value: "UPLD_NAME",
                    align: "center",
                    width: "20%",
                    sortable: true,
                },
                {
                    text: "파일 크기",
                    value: "FILE_SIZE_DISPLAY",
                    align: "center",
                    width: "5%",
                    sortable: true,
                },
                {
                    text: "등록자",
                    value: "REG_ID",
                    align: "center",
                    width: "7%",
                    sortable: true,
                },
                {
                    text: "수정자",
                    value: "UPD_ID",
                    align: "center",
                    width: "7%",
                    sortable: true,
                },
                {
                    text: "등록일시",
                    value: "REG_DT",
                    align: "center",
                    width: "10%",
                    sortable: true,
                },
                {
                    text: "수정일시",
                    value: "UPD_DT",
                    align: "center",
                    width: "10%",
                    sortable: true,
                },
                {
                    text: "수정",
                    value: "EDIT",
                    width: "3%",
                    align: "center",
                    sortable: false,
                },
                // {
                //     text: "삭제",
                //     value: "DEL",
                //     width: "3%",
                //     align: "center",
                //     sortable: false,
                // },
            ],

            alertMsg: {
                noCntData: "조회된 데이터가 없습니다.",
                donSelectedMsg: "선택된 목록이 없습니다.",
                delConfirmMsg: "삭제하시겠습니까?",
                delSuccMsg: "삭제되었습니다.",
            },

            RDOnly: false,
        };
    },

    methods: {

        fnDownLinkCopy(item) {
            let fileId=item.FILE_ID
            $('#'+fileId).attr('type', 'text');
            $('#'+fileId).select();
            var copy = document.execCommand('copy');
            $('#'+fileId).attr('type', 'hidden');

            if(copy) {
                this.common_alert('복사됐습니다.')
            }else{
                this.common_alert('실패했습니다.')
            }
        },

        //게시판 그리드 데이터 조회
        clickGetList() {
            this.getGridList();
        },

        isActiveRow(item) {
            const activeClass = item === this.selectedRow ? "active" : "";
            return activeClass;
        },

        // 그리드 데이터 호출
        async getGridList() {
            let requestData = {
                headers: {},
                sendData: {},
            };

            requestData.headers["URL"] = "/api/hlw/file/mng/list";
            requestData.headers["SERVICE"] = "file";
            requestData.headers["METHOD"] = "list";
            requestData.headers["ASYNC"] = false;

            requestData.sendData["SEARCH_PARAMS"] = this.SEARCH_PARAMS;

            let responseGridList = await this.common_postCall(requestData);
            let resHeader = responseGridList.HEADER;
            let resData = responseGridList.DATA;
            let fileMaxSize = resHeader.FILE_MAX_SIZE

            // 데이터 조회 오류 시 alert
            if (resHeader.ERROR_FLAG) {
                this.common_alert(this.alertMsg.responseError, "error");
                return false;
            }else{
                this.gridListDataText = resData;
                this.gridListItemSelected = []
                this.FILE_MAX_SIZE = fileMaxSize
            }
        },

        clickNewWrite() {
            this.COP0600Param = {
                flag: "new",
                headerTitle: "신규 등록",
                userId: this.USER_ID,
                fileMaxSize:this.FILE_MAX_SIZE,
            };

            this.showDialog("COP0600");
        },

        fnMultiDelWrite() {
            if (this.gridListItemSelected.length <= 0) {
                this.common_alert(this.alertMsg.donSelectedMsg, "error")
                return
            }

            this.common_confirm(this.alertMsg.delConfirmMsg, this.fnDelYesCall, null, null, null, 'chk')
        },

        fnClickDelWrite(item) {
            if (this.mixin_isEmpty(item)) {
                this.common_alert(this.alertMsg.donSelectedMsg, "error")
                return
            }

            this.common_confirm(this.alertMsg.delConfirmMsg, this.fnDelYesCall, item, null, null, 'chk')
        },

        async fnDelYesCall(item) {
            let requestData = {
                headers: {
                    METHOD: 'delete',
                },
                sendData:{}
            }

            requestData.headers["URL"]="/api/hlw/file/mng/delete";

            let upldIds=[]

            if(this.mixin_isEmpty(item)) {
                this.gridListItemSelected.forEach(element => {
                    upldIds.push(element.UPLD_ID)
                });
            }else{
                upldIds.push(item.UPLD_ID)
            }

            console.log(upldIds)

            // sendData 세팅
            requestData.sendData["UPLD_IDS"]=upldIds
            requestData.sendData["REG_ID"]=this.USER_ID

            let responseGridList = await this.common_postCall(requestData)
            let resHeader = responseGridList.HEADER
            let resData = responseGridList.DATA

            if (resHeader.ERROR_FLAG) {
                this.common_alert(this.alertMsg.error, "error")
                return false;
            }else{
                this.common_alert(this.alertMsg.delSuccMsg)
                this.clickGetList()
            }
        },

        //userTable row 클릭이벤트
        async fnModi(item) {
            this.COP0600Param = {
                flag: "modi",
                headerTitle: '상세',
                userId: this.USER_ID,
                data: item,
            };

            this.showDialog("COP0600");
        },

        showDialog(menu) {
            console.log("showDialog");
            this[`Dialog${menu}`] = true;
        },

        hideDialog(menu) {
            console.log("hideDialog");
            this[`Dialog${menu}`] = false;
            this.clickGetList()
        },

        submitDialog(type) {
            for (let index=0; index < this.COP0600Param.data.length; index++) {
                const element=this.COP0600Param.data[index]
            }
            this.hideDialog(type)
        },

        rowClick: function (item, row) {
            row.select(true);
        },

        fnFileDown(url) {
            location.href=url;
        },

        /**
        * XSS Decode
        */
        decode(strText) {

            //값존재유무 체크
            if (strText === "" || strText == null) {
                return strText;
            }
            strText = strText.toString();

            //문자열 길이가 4이상일 경우만 처리
            if (strText.length <= 3) {
                return strText;
            }
            //순서바뀌면 안나옴
            strText = strText.replaceAll("&lt;", "<");
            strText = strText.replaceAll("&gt;", ">");
            strText = strText.replaceAll("&amp;", "&");
            strText = strText.replaceAll("&quot;", '"');
            strText = strText.replaceAll("&apos;", "'");
            strText = strText.replaceAll("<br/>", "\r");
            strText = strText.replaceAll("<p>", "\n");
            strText = strText.replaceAll("&#91;", "[");
            strText = strText.replaceAll("&#93;", "]");
            strText = strText.replaceAll("&#40;", "(");
            strText = strText.replaceAll("&#41;", ")");

            return strText;
        },
    },

    mounted() {
        this.getGridList()
    },

    computed: {
        
    },

    watch: {

    },
};
</script>
  
<style>
    a {
        text-decoration-line: none;
    }

    .color {
      background: #d4d232!important;
      border-color: #3c50aa!important;
    }
</style>
  